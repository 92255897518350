import { ContentViewStyle } from '@levelapp/softfabric';
import { ImageStyle, StyleProp, TextStyle, ViewStyle } from 'react-native';

export default class PresentationViewStyle extends ContentViewStyle {
    page: StyleProp<ViewStyle> = {
        flex: 1,
        marginTop: 20
    }

    title: StyleProp<TextStyle> = {
        fontSize: 48,
        color: '#FFC646',
        fontFamily: 'Montserrat',
        fontWeight: '700'
    }

    subTitle: StyleProp<TextStyle> = {
        fontSize: 18,
        color: '#535353',
        fontFamily: 'Montserrat',
        fontWeight: '400',
        marginTop: 8
    }

    orange: StyleProp<TextStyle> = {
        color: '#FFC646',
    }

    image: StyleProp<ImageStyle> = {
        height: 544,
        borderRadius: 23
    }

    imageBubble: StyleProp<ViewStyle> = {
        position: 'absolute',
        backgroundColor: '#FFC646',
        paddingHorizontal: 18,
        paddingVertical: 12,
        borderRadius: 30,
        top: 75,
        right: 34
    }

    imageBubbleLeft: StyleProp<ViewStyle> = {
        position: 'absolute',
        backgroundColor: '#FFC646',
        paddingHorizontal: 18,
        paddingVertical: 12,
        borderRadius: 30,
        bottom: 31,
        left: 32
    }

    hexagonLeft: StyleProp<ViewStyle> = {
        position: 'absolute',
        bottom: 32,
        left: 32
    }

    imageBubbleLeftDown: StyleProp<ViewStyle> = {       
        backgroundColor: '#615D8A',
        paddingHorizontal: 18,
        paddingVertical: 12,
        borderRadius: 30,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    }


    imageBubbleLeftView: StyleProp<ViewStyle> = {
        position: 'absolute',      
        flexDirection: 'column',
        top: 32,
        right: 32
    }

    imageBubbleTextWhite: StyleProp<TextStyle> = {
        fontSize: 20,
        color: 'white',
        fontFamily: 'Montserrat',
        fontWeight: '400',
    }

    imageBubbleText: StyleProp<TextStyle> = {
        fontSize: 20,
        color: '#535353',
        fontFamily: 'Montserrat',
        fontWeight: '400',
    }

    hexagons: StyleProp<ViewStyle> = {
        position: 'absolute',
        flexDirection: 'row',
        bottom: -65,        
        width: '100%'
    }

    hexagon: StyleProp<ViewStyle> = {
       flex: 1,
       alignContent: 'center',
       alignItems: 'center'
    }
}