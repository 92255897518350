import { ContentViewStyle } from '@levelapp/softfabric';
import { StyleProp, TextStyle, ViewStyle } from 'react-native';

export default class InvestCtaViewStyle extends ContentViewStyle {
    page: React.CSSProperties = {
        backgroundImage: 'url(" https://beebondsstorage.blob.core.windows.net/files/web/investBackground.png")', // Remplacez par l'URL de votre image
        backgroundSize: 'cover', // Ajuste la taille de l'image
        backgroundPosition: 'center', // Centre l'image
        backgroundColor: 'yellow',
        width: '100%',
        marginTop: 92,
        textAlign: 'center',
        padding: '20px',
        paddingBottom: 150
    }

    title: StyleProp<TextStyle> = {
        fontSize: 36,
        color: '#535353',
        fontFamily: 'Montserrat',
        fontWeight: "900",
        marginTop: 149
    }

    subTitle: StyleProp<TextStyle> = {
        fontSize: 24,
        color: '#535353',
        fontFamily: 'Montserrat',
        fontWeight: "400",
        marginTop: 44,
        width: 510,
        textAlign: 'center',
        alignSelf: 'center'
    }

    row: StyleProp<ViewStyle> = {
        flexDirection: 'row',
        marginTop: 90,
        alignSelf: 'center'
    }

    investButton: StyleProp<ViewStyle> = {
        backgroundColor: '#535353',
        borderRadius: 24,
        height: 49,
        paddingHorizontal: 78,
        justifyContent: 'center',
    }

    investText: StyleProp<TextStyle> = {
        fontSize: 24,
        color: '#FFC646',
        fontFamily: 'Montserrat',
        fontWeight: "600",
    }

    contactButton: StyleProp<ViewStyle> = {
        backgroundColor: 'transparent',
        borderRadius: 24,
        height: 49,
        paddingHorizontal: 78,
        justifyContent: 'center',
        borderColor: '#535353',
        borderWidth: 1,
        marginLeft: 20
    }

    contactText: StyleProp<TextStyle> = {
        fontSize: 24,
        color: '#535353',
        fontFamily: 'Montserrat',
        fontWeight: "600",
    }
}