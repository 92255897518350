import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import QuestionsViewState from './QuestionsViewState';
import QuestionsViewProps from './QuestionsViewProps';

export default class QuestionsViewModel extends ContentViewModel<QuestionsViewState, QuestionsViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings

    }
}