import {ContentViewStyle} from '@levelapp/softfabric';
import {StyleProp,ViewStyle} from 'react-native';

export default class DetailsViewStyle extends ContentViewStyle
{
    page: StyleProp<ViewStyle> = {
        flex: 1,
        width: '80%',
        alignSelf: 'center',
    }

    columns: StyleProp<ViewStyle> = {
        flex: 1,       
        flexDirection: 'row' ,
        width: '100%',
    }

    column: StyleProp<ViewStyle> = {
        width: '65%'
    }

    columnRight: StyleProp<ViewStyle> = {
        alignItems: 'flex-end',
        width: '35%'
    }
}