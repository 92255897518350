import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import PresentationViewState from './PresentationViewState';
import PresentationViewProps from './PresentationViewProps';

export default class PresentationViewModel extends ContentViewModel<PresentationViewState, PresentationViewProps> {
    /* CONSTRUCTOR */
    constructor(view: ContentView) {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings

    }

    formatAnnualInterestRate() {
        return this.props().project.annualInterestRate + "%";
    }

    formatMaturity() {
        return (this.props().project.duration > 1) ? this.props().project.duration + " " + this.translate("years")
            : this.props().project.duration + " " + this.translate("year");
    }

    getBackgroundColor() {
        switch (this.props().project.projectType.title) {
            case 'FINANCED':
                return '#F7A335';
            case 'STUDY':
                return '#C7597C';
            case 'FINANCING':
                return '#2A8A8D';
            case 'PREVIEW':
                return 'red';
            default:
                return '#FFFFFF'; // Couleur par défaut si aucune condition ne correspond
        }
    }
}