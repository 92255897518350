import { ContentViewStyle } from '@levelapp/softfabric';
import { StyleProp, TextStyle, ViewStyle } from 'react-native';

export default class FooterViewStyle extends ContentViewStyle {
    page: StyleProp<ViewStyle> = {
        flex: 1
    }

    footer: React.CSSProperties = {
        fontFamily: 'Montserrat',
        fontWeight: 'bold',
        backgroundColor: '#535353',
        backgroundImage: 'url("https://beebondsstorage.blob.core.windows.net/files/web/footerBackground.png")', // Remplacez par l'URL de votre image
        backgroundSize: 'cover', // Ajuste la taille de l'image
        backgroundPosition: 'center', // Centre l'image
        width: '100%',
        fontSize: 14,
        color: '#f7f7f7',
        marginTop: 50,
        textAlign: 'center',
        padding: '20px',
        height: 255
    }

    footerText: StyleProp<TextStyle> = {
        fontSize: 14,
        color: '#f7f7f7',
        fontFamily: 'Montserrat',
    }

    footerTitle: StyleProp<TextStyle> = {
        fontSize: 24,
        color: '#FFC646',
        fontFamily: 'Montserrat',
        fontWeight: '700',
        marginTop: 20
    }

    legalsView: StyleProp<ViewStyle> = {
        flex: 1,
        flexDirection: 'row',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
        marginTop: 32
    }

    footerLink: StyleProp<TextStyle> = {
        fontSize: 16,
        color: 'white',
        fontFamily: 'Montserrat',
        fontWeight: '600',
    }
}