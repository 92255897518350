import React from 'react';
import { ContentView } from '@levelapp/softfabric';
import DocumentsViewState from './DocumentsViewState';
import DocumentsViewProps from './DocumentsViewProps';
import DocumentsViewStyle from './DocumentsViewStyle';
import DocumentsViewModel from './DocumentsViewModel';
import { TranslationView } from '@levelapp/softfabric-ui';
import { Image, Text, View } from 'react-native';
import ProjectDocument from '../../../../../Common/DTO/ProjectDocument';
import Helpers from '../../../../../Common/Helpers/AmountHelpers';

export default class DocumentsView extends ContentView<DocumentsViewProps, DocumentsViewState, DocumentsViewModel, DocumentsViewStyle> {
  /* CONSTRUCTOR */
  constructor(props: DocumentsViewProps) {
    super(props);

    // Binding
    this.bind(new DocumentsViewModel(this), new DocumentsViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={this.style.page}>
        <TranslationView style={this.style.title}>documents</TranslationView>
        <View style={this.style.documents}>
          {this.props.projectDocuments.map((document: ProjectDocument, index: number) => {
            return (
              <div style={{cursor: 'pointer'}}>
                <View key={index} style={this.style.row}>
                  <Image source={require('./assets/file-pdf.png')} style={{ height: 32, width: 32 }} />
                  <a href={document.url} style={{textDecoration: 'none'}} target='_blank'>
                  <Text style={this.style.documentTitle}>{Helpers.getCorrectTranslation(document.title)}</Text>
                  </a>
                </View>
              </div>
            )
          })}
          <Text style={this.style.infoText}>{Helpers.getCorrectTranslation(this.props.documentsLegalInfoText)}</Text>
        </View>

      </View>

    );
  }
}