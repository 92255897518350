import React from 'react';
import { ContentView } from '@levelapp/softfabric';
import InvestCtaViewState from './InvestCtaViewState';
import InvestCtaViewProps from './InvestCtaViewProps';
import InvestCtaViewStyle from './InvestCtaViewStyle';
import InvestCtaViewModel from './InvestCtaViewModel';
import { TranslationView } from '@levelapp/softfabric-ui';
import { Text, TouchableOpacity, View } from 'react-native';
import Helpers from '../../../../../Common/Helpers/AmountHelpers';

export default class InvestCtaView extends ContentView<InvestCtaViewProps, InvestCtaViewState, InvestCtaViewModel, InvestCtaViewStyle> {
  /* CONSTRUCTOR */
  constructor(props: InvestCtaViewProps) {
    super(props);

    // Binding
    this.bind(new InvestCtaViewModel(this), new InvestCtaViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <div className="container" style={this.style.page}>
        <View>
          <Text style={this.style.title}>{this.binding.translate("InvestCTAText")} {this.props.project.projectName}</Text>
          <Text style={this.style.subTitle}>{Helpers.getCorrectTranslation(this.props.project.projectIntroduction)}</Text>
          <View style={this.style.row}>
            <div style={{ cursor: 'pointer' }}>
              <View style={this.style.investButton}>
                <TranslationView style={this.style.investText}>invest</TranslationView>
              </View>
            </div>
            <div style={{ cursor: 'pointer' }}>
              <TouchableOpacity onPress={this.binding.navigateToContact}>
                <View style={this.style.contactButton}>
                  <TranslationView style={this.style.contactText}>Contact</TranslationView>
                </View>
              </TouchableOpacity>
            </div>
          </View>
        </View>
      </div>
    );
  }
}