import { ContentViewStyle } from '@levelapp/softfabric';
import { ImageStyle, StyleProp, TextStyle, ViewStyle } from 'react-native';

export default class InvestBoxViewStyle extends ContentViewStyle {
    box: StyleProp<ViewStyle> = {
        backgroundColor: '#535353',
        width: 448,
        borderRadius: 24,
        alignItems: 'center',
        paddingVertical: 52,
        paddingHorizontal: 20,
        marginTop: 47,
        height: 690,
        overflow: 'hidden',  // Ajouté ici,
        maxWidth: '100%'
    }

    imageBackground: StyleProp<ImageStyle> = {
        position: 'absolute',
        height: 690,
        width: 448,

    }

    title: StyleProp<TextStyle> = {
        fontSize: 40,
        color: 'white',
        fontFamily: 'Montserrat',
        fontWeight: '800',
        marginBottom: 39
    }

    subTitle: StyleProp<TextStyle> = {
        fontSize: 20,
        color: 'white',
        fontFamily: 'Montserrat',
        fontWeight: '600',
        marginTop: 12,
        marginBottom: 37
    }

    amountsText: StyleProp<TextStyle> = {
        fontSize: 12,
        color: 'white',
        fontFamily: 'Montserrat',
        fontWeight: '600',
    }

    amountsTextRight: StyleProp<TextStyle> = {
        fontSize: 12,
        color: 'white',
        fontFamily: 'Montserrat',
        fontWeight: '600',
        textAlign: 'right'
    }

    amountsBeforBarView: StyleProp<ViewStyle> = {
        alignSelf: 'flex-start',
        width: '100%',
        flexDirection: 'row',
        marginBottom: 6
    }

    amountsView: StyleProp<ViewStyle> = {
        alignSelf: 'flex-start',
        width: '100%',
        flexDirection: 'row',
        marginTop: 6,
        marginBottom: 61
    }

    amountsViewsRight: StyleProp<ViewStyle> = {
    }

    infosView: StyleProp<ViewStyle> = {
        alignSelf: 'flex-start',
        width: '100%',
        flexDirection: 'row',
        marginTop: 71
    }

    infosViewValues: StyleProp<ViewStyle> = {
        flex: 1,
    }

    titleText: StyleProp<TextStyle> = {
        fontSize: 32,
        color: 'white',
        fontFamily: 'Montserrat',
        fontWeight: '700'
    }

    infoText: StyleProp<TextStyle> = {
        fontSize: 16,
        color: 'white',
        fontFamily: 'Montserrat',
        fontWeight: '700'
    }

    infoValue: StyleProp<TextStyle> = {
        fontSize: 16,
        color: 'white',
        fontFamily: 'Montserrat',
        fontWeight: '400',
        textAlign: 'right'
    }

    btn: StyleProp<ViewStyle> = {
        backgroundColor: '#FFA93B',
        height: 64,
        width: '100%',
        borderRadius: 16,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 74
    }

    btnText: StyleProp<TextStyle> = {
        fontSize: 20,
        color: '#535353',
        fontFamily: 'Montserrat',
        fontWeight: '600'
    }

    line: StyleProp<ViewStyle> = {
        backgroundColor: '#535353',
        height: 1,
        width: '100%',
        marginBottom: 8,
        marginTop: 107
    }

    footer: StyleProp<TextStyle> = {
        fontSize: 14,
        color: '#535353',
        fontFamily: 'Montserrat',
        fontWeight: '400'
    }
}