import React from 'react';
import { ContentView } from '@levelapp/softfabric';
import ImagesPresentationViewState from './ImagesPresentationViewState';
import ImagesPresentationViewProps from './ImagesPresentationViewProps';
import ImagesPresentationViewStyle from './ImagesPresentationViewStyle';
import ImagesPresentationViewModel from './ImagesPresentationViewModel';
import { Image, ScrollView, View } from 'react-native';

export default class ImagesPresentationView extends ContentView<ImagesPresentationViewProps, ImagesPresentationViewState, ImagesPresentationViewModel, ImagesPresentationViewStyle> {
  /* CONSTRUCTOR */
  constructor(props: ImagesPresentationViewProps) {
    super(props);

    // Binding
    this.bind(new ImagesPresentationViewModel(this), new ImagesPresentationViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={this.style.page}>
        <ScrollView horizontal={true} style={this.style.scrollView}>
        <View style={this.style.line}>
          {this.props.images.filter((x:any) => x.type == 'GENERAL').map((image: any, index: number) => {
            return (
              <Image key={index} style={this.style.image} source={{ uri: image.url }} />
            )
          })}
        </View>
        </ScrollView>
      </View>
    );
  }
}