import { ContentViewStyle } from '@levelapp/softfabric';
import { StyleProp, TextStyle, ViewStyle } from 'react-native';

export default class CalendarViewStyle extends ContentViewStyle {
    page: StyleProp<ViewStyle> = {
        marginTop: 95
    }

    title: StyleProp<TextStyle> = {
        fontSize: 36,
        color: '#535353',
        fontFamily: 'Montserrat',
        fontWeight: '700',
        marginBottom: 64
    }

    row: StyleProp<ViewStyle> = {
        display: 'flex',
        flexDirection: 'row',
        width: "100%",
        marginTop: 80
    }

    textView: StyleProp<ViewStyle> = {
        marginLeft: 28,
        marginTop: -20,
        width: "100%"
    }

    date: StyleProp<TextStyle> = {
        fontSize: 20,
        color: '#535353',
        fontFamily: 'Montserrat',
        fontWeight: '900'
    }

    event: StyleProp<TextStyle> = {
        fontSize: 24,
        color: '#535353',
        fontFamily: 'Montserrat',
        fontWeight: '400',
        width: "100%"
    }

    container: StyleProp<ViewStyle> = {
        alignItems: 'center',
        position: 'absolute',
        left: 16,
        top: 150,
        height: '81%'
      }
      // La ligne verticale
      arrowView: StyleProp<ViewStyle> = {
        height: '100%',
        backgroundColor: '#535353',
        width: 1,
      }
      // La boule en haut (vue circulaire)
      circle: StyleProp<ViewStyle> = {
        width: 15,  // Diamètre de la boule
        height: 15,
        borderRadius: 15 / 2,  // 50% pour rendre la vue circulaire
        backgroundColor: '#535353',
      }
      // La flèche en bas (triangle)
      triangle: StyleProp<ViewStyle> = {
        width: 0,
        height: 0,
        borderLeftWidth: 7.5,  // Largeur de la flèche à gauche
        borderRightWidth: 7.5,  // Largeur de la flèche à droite
        borderTopWidth: 15,     // Hauteur de la flèche (modifié de borderBottomWidth à borderTopWidth)
        borderLeftColor: 'transparent',
        borderRightColor: 'transparent',
        borderTopColor: '#535353',  // Couleur de la flèche (utilise borderTopColor)
    }
    
    
}