import {ContentViewStyle} from '@levelapp/softfabric';
import {ImageStyle, StyleProp,TextStyle,ViewStyle} from 'react-native';

export default class PreviousProjectsViewStyle extends ContentViewStyle
{
    page: StyleProp<ViewStyle> = {
        flex: 1,
        marginTop: 64
    }

    title: StyleProp<TextStyle> = {
        fontSize: 24,
        color: '#535353',
        fontFamily: 'Montserrat',
        fontWeight: '700', 
    }

    row: StyleProp<ViewStyle> = {
        flexDirection: 'row',
        width: '100%',
        marginTop: 32
    }

    box: StyleProp<ViewStyle> = {
        flex: 1,
    }

    image: StyleProp<ImageStyle> = {
        width: 247,
        height: 247,
        borderRadius: 24,
    }

    projectTitle: StyleProp<TextStyle> = {
        fontSize: 18,
        color: '#535353',
        fontFamily: 'Montserrat',
        fontWeight: '700', 
        marginTop: 24
    }

    projectLocalisation: StyleProp<TextStyle> = {
        fontSize: 18,
        color: '#535353',
        fontFamily: 'Montserrat',
        fontWeight: '600', 
        marginTop: 20
    }

    projectText: StyleProp<TextStyle> = {
        fontSize: 16,
        color: '#535353',
        fontFamily: 'Montserrat',
        fontWeight: '400', 
        marginTop: 20,
        textAlign: 'justify',
        width: 247
    }

    lastItem: StyleProp<ViewStyle> = {
        alignItems: 'flex-end',  

    }

    secondItem: StyleProp<ViewStyle> = {
        alignItems: 'center',  

    }
}