import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import AdvantagesViewState from './AdvantagesViewState';
import AdvantagesViewProps from './AdvantagesViewProps';

export default class AdvantagesViewModel extends ContentViewModel<AdvantagesViewState, AdvantagesViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings

    }
}