import * as React from 'react';
import "reflect-metadata";
import ShellView from './Presentation/ShellView';
import { Application, TranslationManager, HttpManager, Managers, LanguageManager, NavigationManager, PopUpManager, AuthManager, StorageManager, } from '@levelapp/softfabric';
import { UiManagers } from '@levelapp/softfabric-ui';
import MyStyleManager from './Common/Managers/MyStyleManager';
import UrlConstants from './Common/Constants/UrlConstants';

export default class App extends Application {
  initialisation() {

    Application.current = this;

    this.register(Managers.STORAGE_MANAGER, new StorageManager());

    this.register(Managers.NAVIGATION_MANAGER, new NavigationManager());

    this.register(Managers.AUTH_MANAGER,
      new AuthManager(UrlConstants.BB_BACKEND!,
        "31BB45F68BEE96AC4D94DFDBC93E8",
        this.resolve(Managers.STORAGE_MANAGER)));

    this.resolve<AuthManager>(Managers.AUTH_MANAGER).handleNoTokenFound = this.handleNoTokenFound;

    //Current language && Array Accepted Languages
    var path = document.location.pathname;
    let acceptedLanguages = ["fr-BE", "nl-BE", "en-GB"];

    if (path.split("/").length > 2 && acceptedLanguages.filter(language => language == path.split("/")[1]).length > 0) {
      let language = path.split("/")[1];
      console.log(language);
      this.register(Managers.LANGUAGE_MANAGER, new LanguageManager(language, ["fr-BE", "nl-BE", "en-GB"]));
    }
    else {
      this.register(Managers.LANGUAGE_MANAGER, new LanguageManager("fr-BE", ["fr-BE", "nl-BE", "en-GB"]));
    }

    //Registrer The Translation Manager with a path to the translation.json
    this.register(Managers.TRANSLATION_MANAGER, new TranslationManager(require('./Translations/Translations.json')));

    //To modify the theme of your app. Specify a new Style Manager.
    //Create a ExtendedManager extends StyleManager 
    this.register(UiManagers.STYLE_MANAGER, new MyStyleManager());

    //Set a httpClient with an url and a callback
    this.register(Managers.HTTP_MANAGER, new HttpManager(UrlConstants.BB_BACKEND!, async () => {
      let auth = this.resolve<AuthManager>(Managers.AUTH_MANAGER);
      var token = await auth.getTokenFromDisk();
      if (token) {
        return "Bearer" + ' ' + token.access_token;
      }

      else {
        return '';
      }
      return '';
    }, this.resolve(Managers.AUTH_MANAGER)));



    //Set the PopUp Manager
    this.register(Managers.POPUP_MANAGER, new PopUpManager());


    //TODO
    //Set a callback for handling errors differents than 404 and 401
    this.resolve<HttpManager>(Managers.HTTP_MANAGER).handleSomethingWentWrong = this.handleSomethingWentWrong;
    this.resolve<HttpManager>(Managers.HTTP_MANAGER).handleUnauthorized = this.handleUnauthorized;
    this.resolve<HttpManager>(Managers.HTTP_MANAGER).handleNotFound = this.handleNotFound;
  }


  handleSomethingWentWrong() {
    console.log('SomethingWentWrong')
    //Application.current.resolve<NavigationManager>(Managers.NAVIGATION_MANAGER).navigate('/login');
  }

  handleNotFound() {
    console.log('ErrorNotFound')
  }

  handleNoTokenFound() {
    //Application.current.resolve<NavigationManager>(Managers.NAVIGATION_MANAGER).navigate('/login');
  }

  handleUnauthorized() {
    console.log('Unauthorized', Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER))

    Application.current.resolve<NavigationManager>(Managers.NAVIGATION_MANAGER).navigate('/login');
  }

  checkLanguage() {

  }


  renderApplication() {
    return (<ShellView />);
  }
}