import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import VideosViewState from './VideosViewState';
import VideosViewProps from './VideosViewProps';

export default class VideosViewModel extends ContentViewModel<VideosViewState, VideosViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings

    }
}