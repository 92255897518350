import React from 'react';
import { ContentView } from '@levelapp/softfabric';
import PreviousProjectsViewState from './PreviousProjectsViewState';
import PreviousProjectsViewProps from './PreviousProjectsViewProps';
import PreviousProjectsViewStyle from './PreviousProjectsViewStyle';
import PreviousProjectsViewModel from './PreviousProjectsViewModel';
import { TranslationView } from '@levelapp/softfabric-ui';
import { Image, Text, View } from 'react-native';
import PreviousProject from '../../../../../Common/DTO/PreviousProject';
import Helpers from '../../../../../Common/Helpers/AmountHelpers';

export default class PreviousProjectsView extends ContentView<PreviousProjectsViewProps, PreviousProjectsViewState, PreviousProjectsViewModel, PreviousProjectsViewStyle> {
  /* CONSTRUCTOR */
  constructor(props: PreviousProjectsViewProps) {
    super(props);

    // Binding
    this.bind(new PreviousProjectsViewModel(this), new PreviousProjectsViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={this.style.page}>
        <TranslationView style={this.style.title}>leader.previousProjects</TranslationView>
        <View style={this.style.row}>
          {this.props.previousProjects.map((p: PreviousProject, index: number) => {
            // Détection du dernier et du deuxième élément
            const isLastItem = index === this.props.previousProjects.length - 1;
            const isSecondItem = index === 1;

            return (
              <View
                key={index}
                style={[
                  this.style.box,  // Style de base
                  isLastItem && this.style.lastItem,  // Style du dernier élément
                  isSecondItem && this.style.secondItem  // Style du deuxième élément
                ]}
              >
                <Image style={this.style.image} source={{ uri: p.pictureUrl }} />
                <View>
                  <Text style={this.style.projectTitle}>{p.name}</Text>
                  <Text style={this.style.projectLocalisation}>{Helpers.getCorrectTranslation(p.localisation)}</Text>
                  <Text style={this.style.projectText}>{Helpers.getCorrectTranslation(p.description)}</Text>
                </View>
              </View>
            )
          })}
        </View>
      </View>
    );
  }
}