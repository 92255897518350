import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import FooterViewState from './FooterViewState';
import FooterViewProps from './FooterViewProps';

export default class FooterViewModel extends ContentViewModel<FooterViewState, FooterViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings

    }
}