import {ContentViewStyle} from '@levelapp/softfabric';
import {ImageStyle, StyleProp,TextStyle,ViewStyle} from 'react-native';

export default class LocalisationViewStyle extends ContentViewStyle
{
    page: StyleProp<ViewStyle> = {
        flex: 1
    }

    title: StyleProp<TextStyle> = {
        fontSize: 36,
        color: '#535353',
        fontFamily: 'Montserrat',
        fontWeight: '700'
    }

    text: StyleProp<TextStyle> = {
        fontSize: 16,
        color: '#535353',
        fontFamily: 'Montserrat',
        fontWeight: '400',
        marginTop: 64
    }

    map: StyleProp<ViewStyle> = {
        marginTop: 32,
        height: 496
    }

    line: StyleProp<ViewStyle> = {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        marginTop: 20
    }

    image: StyleProp<ImageStyle> = {
        height: 375,
        width: 380,
        resizeMode: 'cover',
        marginRight: 16        
    }
}