import { ContentViewStyle } from '@levelapp/softfabric';
import { StyleProp, TextStyle, ViewStyle } from 'react-native';

export default class HexagonViewStyle extends ContentViewStyle {
  hexagon: StyleProp<ViewStyle> = {
    width: 180, 
    height: 180, 
  }

  hexagonInner: StyleProp<ViewStyle> = {
    width: '100%',
    height: '100%',
  }

  title: StyleProp<TextStyle> = {
    fontSize: 18,
    color: '#535353',
    fontFamily: 'Montserrat',
    fontWeight: '700',
    textAlign: 'center',
    marginTop: 38
  }

  subTitle: StyleProp<TextStyle> = {
    fontSize: 16,
    color: '#535353',
    fontFamily: 'Montserrat',
    fontWeight: '400',
    textAlign: 'center'
  }

  separator: StyleProp<ViewStyle> = {
    backgroundColor: '#535353',
    width: 81,
    height: 1,
    marginTop: 15,
    marginBottom: 10,
    alignSelf: 'center'
  }

  secondTitle: StyleProp<TextStyle> = {
    fontSize: 18,
    color: '#535353',
    fontFamily: 'Montserrat',
    fontWeight: '700',
    textAlign: 'center',
  }
}