import { ContentViewStyle } from '@levelapp/softfabric';
import { ImageStyle, StyleProp, TextStyle, ViewStyle } from 'react-native';

export default class LeaderViewStyle extends ContentViewStyle {
    page: StyleProp<ViewStyle> = {
    }

    title: StyleProp<TextStyle> = {
        fontSize: 36,
        color: '#535353',
        fontFamily: 'Montserrat',
        fontWeight: '700',
        marginTop: 83
    }

    subTitle: StyleProp<TextStyle> = {
        fontSize: 24,
        color: '#535353',
        fontFamily: 'Montserrat',
        fontWeight: '700',
        marginTop: 64
    }

    organigram: StyleProp<ImageStyle> = {
        marginTop: 32,
        width: 928,
        height: 779,
        maxWidth: '100%'
    }

    text: StyleProp<TextStyle> = {
        fontSize: 16,
        color: '#535353',
        fontFamily: 'Montserrat',
        fontWeight: '400',
        marginTop: 24
    }

    tableHeader: StyleProp<ViewStyle> = {
        backgroundColor: '#535353',
        height: 52,
        paddingLeft: 10,
        justifyContent: 'center',
        marginTop: 32
    }

    tableTitle: StyleProp<TextStyle> = {
        fontSize: 16,
        color: '#FFC646',
        fontFamily: 'Montserrat',
        fontWeight: '600'
    }

    row: StyleProp<ViewStyle> = {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    }

    arrayKey: StyleProp<TextStyle> = {
        fontSize: 14,
        color: '#535353',
        fontFamily: 'Montserrat',
        fontWeight: '600',
        flex: 1
    }

    arrayValue: StyleProp<TextStyle> = {
        fontSize: 16,
        color: '#535353',
        fontFamily: 'Montserrat',
        fontWeight: '400',
        flex: 1
    }

    borderView: StyleProp<ViewStyle> = {
        flex: 1,
        paddingLeft: 12,
        borderColor: '#535353',
        borderWidth: 1,
        paddingVertical: 10                
    }
}