import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import LeaderViewState from './LeaderViewState';
import LeaderViewProps from './LeaderViewProps';

export default class LeaderViewModel extends ContentViewModel<LeaderViewState, LeaderViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings

    }
}