import { ContentViewStyle } from '@levelapp/softfabric';
import { StyleProp, TextStyle, ViewStyle } from 'react-native';

export default class FinanceSummaryViewStyle extends ContentViewStyle {
    page: StyleProp<ViewStyle> = {
        marginTop: 128
    }

    title: StyleProp<TextStyle> = {
        fontSize: 36,
        color: '#535353',
        fontFamily: 'Montserrat',
        fontWeight: '700',
        marginBottom: 64
    }

    subTitle: StyleProp<TextStyle> = {
        fontSize: 28,
        color: '#535353',
        fontFamily: 'Montserrat',
        fontWeight: '700',
        marginBottom: 64
    }

    box: StyleProp<ViewStyle> = {
        padding: 32,
        borderRadius: 24,
        shadowColor: "#0000006b",
        shadowOffset: {
            width: 1,
            height: 0,
        },
        shadowOpacity: 0.35,
        shadowRadius: 20.32,
        marginBottom: 20,
        backgroundColor: 'white'
    }

    row: StyleProp<ViewStyle> = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between', 
        alignItems: 'center', 
    }

    yellowLine: StyleProp<ViewStyle> = {
        width: '100%',
        height: 1,
        backgroundColor: '#FFC646',
        marginVertical: 18
    }

    valueKey: StyleProp<TextStyle> = {
        fontSize: 22,
        color: '#535353',
        fontFamily: 'Montserrat',
        fontWeight: '600',
    }

    valueView: StyleProp<ViewStyle> = {
    }

    value: StyleProp<TextStyle> = {
        fontSize: 20,
        color: '#535353',
        fontFamily: 'Montserrat',
        fontWeight: '600',
    }

    rowBoxes: StyleProp<ViewStyle> = {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 12
    }

    yellowBox: StyleProp<ViewStyle> = {
        backgroundColor: "#FFC646",
        borderRadius: 12,
        paddingTop: 12,
        paddingBottom: 12,
        width: 220,
        height: 93,
        paddingLeft: 18
    }

    boxTitle: StyleProp<TextStyle> = {
        fontSize: 16,
        color: '#535353',
        fontFamily: 'Montserrat',
        fontWeight: '700',  
    }

    boxSubTitle: StyleProp<TextStyle> = {
        fontSize: 24,
        color: '#535353',
        fontFamily: 'Montserrat',
        fontWeight: '700',  
        marginTop: 15
    }
}