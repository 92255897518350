import { ContentViewStyle } from '@levelapp/softfabric';
import { StyleProp, ViewStyle, Dimensions } from 'react-native';

export default class ShellViewStyle extends ContentViewStyle
{
    shell: StyleProp<ViewStyle> = {
        display: 'flex',
        flex: 1,
        height: Dimensions.get('window').height
    }
}