import { Application, LanguageManager, Managers } from "@levelapp/softfabric";
import Translation from "../DTO/Translation";

class Helpers {

    static formatAmount(amount: number) {
        return amount.toLocaleString('de-DE') + ' €';;
    }

    static getCorrectTranslation(text: Translation) {
        if(!text)
            return '';
        let language = Application.current.resolve<LanguageManager>(Managers.LANGUAGE_MANAGER).language;
        console.log(language)
        switch (language) {
            case "fr-BE":
                return text.fr;
            case "nl-BE":
                return text.nl;
            case "en-GB":
                return text.en;
            default:
                return text.fr;
        }
    }

    static getPropertyValue(key: string, properties: any) {
        console.log(key)
        console.log(properties)
        let value = properties.filter((x:any) => x.projectPropertyKey.key == key)[0];
        if(!value)
            return "";
        console.log(value);
        if(value.valueTranslated) {
            var trad = this.getCorrectTranslation(value.valueTranslated);
            return trad;
        }
        return value.value;
    }
}

export default Helpers