import React from 'react';
import { ContentView } from '@levelapp/softfabric';
import FinanceSummaryViewState from './FinanceSummaryViewState';
import FinanceSummaryViewProps from './FinanceSummaryViewProps';
import FinanceSummaryViewStyle from './FinanceSummaryViewStyle';
import FinanceSummaryViewModel from './FinanceSummaryViewModel';
import { TranslationView } from '@levelapp/softfabric-ui';
import { Text, View } from 'react-native';
import Helpers from '../../../../../Common/Helpers/AmountHelpers';

export default class FinanceSummaryView extends ContentView<FinanceSummaryViewProps, FinanceSummaryViewState, FinanceSummaryViewModel, FinanceSummaryViewStyle> {
  /* CONSTRUCTOR */
  constructor(props: FinanceSummaryViewProps) {
    super(props);

    // Binding
    this.bind(new FinanceSummaryViewModel(this), new FinanceSummaryViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={this.style.page}>
        <TranslationView style={this.style.title}>financeSummary.title</TranslationView>
        <View style={this.style.box}>
          <TranslationView style={this.style.subTitle}>financeSummary.subTitle</TranslationView>
          {this.props.project.projectPropertyValues.filter(x => x.projectPropertyKey.projectPropertyType.key == "FINANCE_SUMMARY_TAB")
            .map((property: any, index: number, filteredList: any[]) => {
              return (
                <View>
                  <View key={index} style={this.style.row}>
                    <Text style={this.style.valueKey}>{Helpers.getCorrectTranslation(property.projectPropertyKey.title)}</Text>
                    <View style={this.style.valueView}>
                      <Text style={this.style.value}>{Helpers.getPropertyValue(property.projectPropertyKey.key, this.props.project.projectPropertyValues)}</Text>
                    </View>
                  </View>
                  {index < filteredList.length - 1 && ( 
                    <View style={this.style.yellowLine} />
                  )}
                </View>
              )
            })}

        </View>
        <View style={this.style.rowBoxes}>
        {this.props.project.projectPropertyValues.filter(x => x.projectPropertyKey.projectPropertyType.key == "FINANCE_SUMMARY_TAB")
            .map((property: any, index: number) => {
              return (
                <View key={index} style={[this.style.yellowBox, index != 0 ? { marginLeft: 18 } : {}]}>
                  <Text style={this.style.boxTitle}>{Helpers.getCorrectTranslation(property.projectPropertyKey.title)}</Text>
                  <Text style={this.style.boxSubTitle}>{Helpers.getPropertyValue(property.projectPropertyKey.key, this.props.project.projectPropertyValues)}</Text>
              </View>
              )
            })}
        </View>
       
      </View>
    );
  }
}