import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import LocalisationViewState from './LocalisationViewState';
import LocalisationViewProps from './LocalisationViewProps';
import Helpers from '../../../../../Common/Helpers/AmountHelpers';

export default class LocalisationViewModel extends ContentViewModel<LocalisationViewState, LocalisationViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings

    }

    constructGoogleAddress() {
        const google1 = "https://maps.google.com/maps?q=";
        const google2 = "&t=m&z=10&output=embed&iwloc=near";
        const address = Helpers.getPropertyValue("ADRESS", this.props().project.projectPropertyValues);
    
        // Remplacer les espaces par %20 pour l'URL
        const formattedAddress = encodeURIComponent(address);
    
        // Construire l'URL finale
        return google1 + formattedAddress + google2;
    }
}