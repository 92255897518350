import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import ProjectPresentationViewState from './ProjectPresentationViewState';
import ProjectPresentationViewProps from './ProjectPresentationViewProps';
import Helpers from '../../../../../Common/Helpers/AmountHelpers';

export default class ProjectPresentationViewModel extends ContentViewModel<ProjectPresentationViewState, ProjectPresentationViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings

    }

    getpropertyValue(property: any) {
        if(property.value)
            return property.value;
        return Helpers.getCorrectTranslation(property.valueTranslated)
    }

    getPropertyUrl(type:string) {
        return "https://beebondsstorage.blob.core.windows.net/files/web/"+type+".png";
    }
}