import React from 'react';
import { ContentView } from '@levelapp/softfabric';
import VideosViewState from './VideosViewState';
import VideosViewProps from './VideosViewProps';
import VideosViewStyle from './VideosViewStyle';
import VideosViewModel from './VideosViewModel';
import { View } from 'react-native';
import Helpers from '../../../../../Common/Helpers/AmountHelpers';
import { TranslationView } from '@levelapp/softfabric-ui';

export default class VideosView extends ContentView<VideosViewProps, VideosViewState, VideosViewModel, VideosViewStyle> {
  /* CONSTRUCTOR */
  constructor(props: VideosViewProps) {
    super(props);

    // Binding
    this.bind(new VideosViewModel(this), new VideosViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={this.style.page}>
        <TranslationView style={this.style.title}>videos</TranslationView>
        {this.props.properties.filter((x: any) => x.projectPropertyKey.projectPropertyType.key == "VIDEOS").map((property: any, index: number) => {
          return (
            <View key={index} style={this.style.iframe}>
              <iframe 
              style={{border: 'none'}}
              src={Helpers.getPropertyValue(property.projectPropertyKey.key, this.props.properties)} 
              scrolling="no"
              width= {Helpers.getPropertyValue(property.projectPropertyKey.key, this.props.properties).includes("you") ? '100%' : '450'}
              height={Helpers.getPropertyValue(property.projectPropertyKey.key, this.props.properties).includes("you") ? '450' : '100%'}  // Condition pour définir la hauteur
              ></iframe>
            </View>
          )
        })}
      </View>
    );
  }
}