import React from 'react';
import { ContentView } from '@levelapp/softfabric';
import PresentationViewState from './PresentationViewState';
import PresentationViewProps from './PresentationViewProps';
import PresentationViewStyle from './PresentationViewStyle';
import PresentationViewModel from './PresentationViewModel';
import { Image, Text, View } from 'react-native';
import { TranslationView } from '@levelapp/softfabric-ui';
import Helpers from '../../../../../Common/Helpers/AmountHelpers';
import HexagonView from './components/hexagon/HexagonView';

export default class PresentationView extends ContentView<PresentationViewProps, PresentationViewState, PresentationViewModel, PresentationViewStyle> {
  /* CONSTRUCTOR */
  constructor(props: PresentationViewProps) {
    super(props);

    // Binding
    this.bind(new PresentationViewModel(this), new PresentationViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={this.style.page}>
        <View>
          <Image source={{ uri: Helpers.getPropertyValue("HEADER_PICTURE", this.props.project.projectPropertyValues) }} style={this.style.image} />

          {false &&
            <View style={this.style.imageBubbleLeft}>
              <Text style={this.style.imageBubbleText}>{this.binding.translate("rate")} <Text style={{ fontWeight: 'bold' }}>{this.binding.formatAnnualInterestRate()}</Text></Text>

            </View>
          }


          <View style={this.style.hexagonLeft}>
            <HexagonView project={this.props.project} />
          </View>

          <View style={this.style.imageBubbleLeftView}>
            <View style={[this.style.imageBubbleLeftDown, { backgroundColor: this.binding.getBackgroundColor() }]}>
              <TranslationView style={[this.style.imageBubbleText, { color: 'white' }]}>{this.props.project.projectType.title}</TranslationView>
            </View>
            <View style={{ height: 15 }} />
            <View style={this.style.imageBubbleLeftDown}>
              <Text style={this.style.imageBubbleTextWhite}>Avec sureté</Text>
            </View>
          </View>
        </View>
      </View>
    );
  }
}