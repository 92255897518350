import React from 'react';
import { ContentView } from '@levelapp/softfabric';
import FooterViewState from './FooterViewState';
import FooterViewProps from './FooterViewProps';
import FooterViewStyle from './FooterViewStyle';
import FooterViewModel from './FooterViewModel';
import { TranslationView } from '@levelapp/softfabric-ui';
import { Image, Text, View } from 'react-native';

export default class FooterView extends ContentView<FooterViewProps, FooterViewState, FooterViewModel, FooterViewStyle> {
  /* CONSTRUCTOR */
  constructor(props: FooterViewProps) {
    super(props);

    // Binding
    this.bind(new FooterViewModel(this), new FooterViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <div className="container" style={this.style.footer}>
        <View>
          {true &&
            <Text style={this.style.footerText}>
              BeeBonds SRL - Seed Factory - Avenue des Volontaires, 19 - Vrijwilligerslaan, 19 - B - 1160 -Auderghem - Oudergem<br />
              Tel : +32 (0)2 896 92 98 - Contact : hello@beebonds.com - Site WEB : https://www.beebonds.com/ - N° BCE : BE 0658 962 075<br />
              Plateforme de Financement Participatif agréée, le 23 avril 2019, par l'Autorité des Services et Marchés Financiers (FSMA) établie Rue du Congrès 12-14 à B - 1000 - Bruxelles - Tel : +32(0)2 220 52 11
            </Text>
          }

          <TranslationView style={this.style.footerTitle}>legalInformations</TranslationView>
          <View style={this.style.legalsView}>
            <a href='https://www.beebonds.com/wp-content/uploads/2024/02/BeeBonds-Conditions-generales-24-02-02-.pdf' target='_blank' style={{ textDecoration: 'none' }}>
              <TranslationView style={[this.style.footerLink, { marginRight: 50 }]}>generalConditions</TranslationView>
            </a>
            <a href='https://www.beebonds.com/privacy-policy/' target='_blank' style={{ textDecoration: 'none' }}>
              <TranslationView style={[this.style.footerLink, { marginRight: 50 }]}>confPolitic</TranslationView>
            </a>
            <a href='https://www.beebonds.com/wp-content/uploads/2023/03/BeeBonds-formulaire-de-plainte-.pdf' target='_blank' style={{ textDecoration: 'none' }}>
              <TranslationView style={[this.style.footerLink, { marginRight: 50 }]}>FormPleinte</TranslationView>
            </a>
            <a href='https://www.beebonds.com/wp-content/uploads/2023/01/09.01-Procedure-de-Prevention-des-Conflits-dInterets_janvier-2023.pdf' target='_blank' style={{ textDecoration: 'none' }}>
              <TranslationView style={[this.style.footerLink, { marginRight: 50 }]}>PolitiqueConflits</TranslationView>
            </a>
            <a href='https://www.beebonds.com/avertissement/' target='_blank' style={{ textDecoration: 'none' }}>
              <TranslationView style={this.style.footerLink}>WarningOnRisks</TranslationView>
            </a>
          </View>
          <View style={this.style.legalsView}>
            <a href='https://www.linkedin.com/company/beebonds/' target='_blank' style={{ textDecoration: 'none' }}>
              <Image source={require('./assets/linkedin.png')} style={{ height: 32, width: 32, marginRight: 28 }} />
            </a>
            <a href='https://www.facebook.com/beebondsFinance' target='_blank' style={{ textDecoration: 'none' }}>
              <Image source={require('./assets/fb.png')} style={{ height: 32, width: 32, marginRight: 28 }} />
            </a>
            <a href='https://www.instagram.com/beebonds/' target='_blank' style={{ textDecoration: 'none' }}>
              <Image source={require('./assets/insta.png')} style={{ height: 32, width: 32, marginRight: 28 }} />
            </a>
            <a href='https://twitter.com/BeebondsFinance' target='_blank' style={{ textDecoration: 'none' }}>
              <Image source={require('./assets/twitter.png')} style={{ height: 32, width: 32, marginRight: 28 }} />
            </a>
            <a href='https://www.youtube.com/channel/UCnkoOsHOn5OYzbMI2u5Sh2A' target='_blank' style={{ textDecoration: 'none' }}>
              <Image source={require('./assets/yt.png')} style={{ height: 32, width: 32 }} />
            </a>
          </View>
        </View>
      </div>
    );
  }
}