import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import InvestCtaViewState from './InvestCtaViewState';
import InvestCtaViewProps from './InvestCtaViewProps';

export default class InvestCtaViewModel extends ContentViewModel<InvestCtaViewState, InvestCtaViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings

    }

    navigateToContact() {
        window.location.href = "https://www.beebonds.com/contact/";
    }
}