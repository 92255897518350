import {ContentViewStyle} from '@levelapp/softfabric';
import {StyleProp,TextStyle,ViewStyle} from 'react-native';

export default class WarningViewStyle extends ContentViewStyle
{
    page: StyleProp<ViewStyle> = {
        display: 'flex'
    }

    title: StyleProp<TextStyle> = {
        fontSize: 24,
        color: '#535353',
        fontFamily: 'Montserrat',
        fontWeight: '700', 
        marginTop: 32
    }

    text: StyleProp<TextStyle> = {
        fontSize: 16,
        color: '#535353',
        fontFamily: 'Montserrat',
        fontWeight: '400', 
        marginLeft: 32
    }

    box: StyleProp<ViewStyle> = {
        borderRadius: 24,
        shadowColor: "#0000006b",  
        shadowOffset: {
            width: 1,
            height: 0,
        },
        shadowOpacity: 0.35,  
        shadowRadius: 20.32,  
        flexDirection: 'row',
        padding: 18,
        marginTop: 32,
        alignItems: 'center'
    }
}