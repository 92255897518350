import { ContentViewStyle } from '@levelapp/softfabric';
import { ImageStyle, StyleProp, ViewStyle } from 'react-native';

export default class ImagesPresentationViewStyle extends ContentViewStyle {
    page: StyleProp<ViewStyle> = {
        flex: 1,
        maxWidth: '100%',
        marginTop: 82
    }

    scrollView: StyleProp<ViewStyle> = {

    }

    line: StyleProp<ViewStyle> = {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
    }

    image: StyleProp<ImageStyle> = {
        height: 375,
        width: 380,
        resizeMode: 'cover',
        marginLeft: 16
        
    }
}