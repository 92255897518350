import React from 'react';
import { ContentView } from '@levelapp/softfabric';
import HexagonViewState from './HexagonViewState';
import HexagonViewProps from './HexagonViewProps';
import HexagonViewStyle from './HexagonViewStyle';
import HexagonViewModel from './HexagonViewModel';
import { ImageBackground, Text, View } from 'react-native';
import { TranslationView } from '@levelapp/softfabric-ui';

export default class HexagonView extends ContentView<HexagonViewProps, HexagonViewState, HexagonViewModel, HexagonViewStyle> {
  /* CONSTRUCTOR */
  constructor(props: HexagonViewProps) {
    super(props);

    // Binding
    this.bind(new HexagonViewModel(this), new HexagonViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={this.style.hexagon}>
        <ImageBackground
          source={require('./assets/hexagon.png')}
          style={this.style.hexagonInner}>
          <Text style={this.style.title}>{this.binding.formatRate()}</Text>
          <TranslationView style={this.style.subTitle}>rate</TranslationView>
          <View style={this.style.separator} />
          <Text style={this.style.secondTitle}>{this.binding.formatDuration()}</Text>
          <TranslationView style={this.style.subTitle}>duration</TranslationView>
        </ImageBackground>
      </View>
    );
  }
}