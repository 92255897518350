import {ContentViewStyle} from '@levelapp/softfabric';
import {ImageStyle, StyleProp,TextStyle,ViewStyle} from 'react-native';

export default class ProjectPresentationViewStyle extends ContentViewStyle
{
    page: StyleProp<ViewStyle> = {
        flex: 1
    }

    title: StyleProp<TextStyle> = {
        fontSize: 36,
        color: '#535353',
        fontFamily: 'Montserrat',
        fontWeight: '700',  
    }

    line: StyleProp<ViewStyle> = {
        flex: 1,
        flexDirection: 'row',
        marginTop: 32
    }

    box: StyleProp<ViewStyle> = {
        flex: 1,
        backgroundColor: "#FFC646",
        height: 59,
        borderRadius: 12,
        paddingTop: 12,
        paddingBottom: 12,
        flexDirection: 'row'
    }

    boxTitle: StyleProp<TextStyle> = {
        fontSize: 12,
        color: '#535353',
        fontFamily: 'Montserrat',
        fontWeight: '500',  
    }

    boxSubTitle: StyleProp<TextStyle> = {
        fontSize: 14,
        color: '#535353',
        fontFamily: 'Montserrat',
        fontWeight: '600',  
        marginTop: 0
    }

    boxDescription: StyleProp<TextStyle> = {
        fontSize: 16,
        color: '#535353',
        fontFamily: 'Montserrat',
        fontWeight: '400',
        marginTop: 38
    }

    icon: StyleProp<ImageStyle> = {
       height: 32,
       width: 32,
       marginLeft: 12,
       marginRight: 8
    }
}