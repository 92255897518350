import React from 'react';
import { ContentView } from '@levelapp/softfabric';
import NavigationBarViewState from './NavigationBarViewState';
import NavigationBarViewProps from './NavigationBarViewProps';
import NavigationBarViewStyle from './NavigationBarViewStyle';
import NavigationBarViewModel from './NavigationBarViewModel';
import { TranslationView } from '@levelapp/softfabric-ui';
import { View } from 'react-native';

export default class NavigationBarView extends ContentView<NavigationBarViewProps, NavigationBarViewState, NavigationBarViewModel, NavigationBarViewStyle> {
  /* CONSTRUCTOR */
  constructor(props: NavigationBarViewProps) {
    super(props);

    // Binding
    this.bind(new NavigationBarViewModel(this), new NavigationBarViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={this.style.page}>
        <View style={[this.style.selected, { marginRight: 34 }]}>
          <TranslationView style={[this.style.menu, ]}>general</TranslationView>
        </View>
        <View style={[{ marginRight: 34 }]}>
          <TranslationView style={[this.style.menu,]}>localisation</TranslationView>
        </View>
        <View style={[{ marginRight: 34 }]}>
          <TranslationView style={[this.style.menu, ]}>timeline</TranslationView>
        </View>
        <View style={[{ marginRight: 34 }]}>
          <TranslationView style={[this.style.menu, ]}>porteur</TranslationView>
        </View>
        <View style={[{ marginRight: 34 }]}>
          <TranslationView style={[this.style.menu, ]}>finance</TranslationView>
        </View>
        <View style={[{ marginRight: 34 }]}>
          <TranslationView style={this.style.menu}>documents</TranslationView>
        </View>

      </View>
    );
  }
}