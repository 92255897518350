import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import ImagesPresentationViewState from './ImagesPresentationViewState';
import ImagesPresentationViewProps from './ImagesPresentationViewProps';

export default class ImagesPresentationViewModel extends ContentViewModel<ImagesPresentationViewState, ImagesPresentationViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings

    }
}