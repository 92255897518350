import { StyleManager } from "@levelapp/softfabric-ui";

export default class MyStyleManager extends StyleManager {
    acceptButtonColor = "#FFB243";

    /* FONT FAMILY */
    fontFamily = "Montserrat";


    /* COLOR */
    primaryColor = "#FFB243";
    menuFontColor = "#545454";
    textFontColor = "#565656";
    boldFontColor = "#565656";

    /*FONT SIZE */
    menuTitleSize = 15;
}