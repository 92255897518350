import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import DetailsViewState from './DetailsViewState';
import DetailsViewProps from './DetailsViewProps';
import ProjectsService from '../../../Proxy/ProjectsService';
import Project from '../../../Common/DTO/Project';

export default class DetailsViewModel extends ContentViewModel<DetailsViewState, DetailsViewProps> {
    _projectsService: ProjectsService;


    /* CONSTRUCTOR */
    constructor(view: ContentView) {
        super(view);

        this.initialState({
            project: undefined
        }); // Initialize your state here

        this._projectsService = new ProjectsService();
    }

    componentDidMount() {
        this._projectsService.getByCode(this.props().code, (project: Project) => {
            console.log(project)
            this.setField({ project });
        })
    }
}