import React from 'react';
import { ContentView } from '@levelapp/softfabric';
import AdvantagesViewState from './AdvantagesViewState';
import AdvantagesViewProps from './AdvantagesViewProps';
import AdvantagesViewStyle from './AdvantagesViewStyle';
import AdvantagesViewModel from './AdvantagesViewModel';
import { TranslationView } from '@levelapp/softfabric-ui';
import { Text, View } from 'react-native';
import ProjectBenefit from '../../../../../Common/DTO/ProjectBenefit';
import Helpers from '../../../../../Common/Helpers/AmountHelpers';

export default class AdvantagesView extends ContentView<AdvantagesViewProps, AdvantagesViewState, AdvantagesViewModel, AdvantagesViewStyle> {
  /* CONSTRUCTOR */
  constructor(props: AdvantagesViewProps) {
    super(props);

    // Binding
    this.bind(new AdvantagesViewModel(this), new AdvantagesViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={this.style.page}>
        <TranslationView style={this.style.title}>reasonsToInvest</TranslationView>
        {this.props.projectBenefits.map((benefit: ProjectBenefit, index: number) => {
          return (
            <View key={index} style={this.style.box}>
              <View style={this.style.boxTitleView}>
                <Text style={this.style.boxOrder}>{benefit.order}</Text>
                <Text style={this.style.boxTitle}>{Helpers.getCorrectTranslation(benefit.title)}</Text>
              </View>
              <Text style={this.style.boxText}>{Helpers.getCorrectTranslation(benefit.subtitle)}</Text>
            </View>
          )
        })}
      </View>
    );
  }
}