import {ContentViewStyle} from '@levelapp/softfabric';
import {StyleProp,TextStyle,ViewStyle} from 'react-native';

export default class NavigationBarViewStyle extends ContentViewStyle
{
    page: StyleProp<ViewStyle> = {
        backgroundColor: '#FCFCFC',
        borderRadius: 12,
        height: 58,
        shadowColor: "#0000006b",
        shadowOffset: {
            width: 1,
            height: 0,
        },
        shadowOpacity: 0.35,
        shadowRadius: 20.32,
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: 8,
        maxWidth: '100%'
    }

    menu: StyleProp<TextStyle> = {
        fontSize: 18,
        color: '#535353',
        fontFamily: 'Montserrat',
        fontWeight: '500',
        textAlign: 'center'
    }

    selected: StyleProp<ViewStyle> = {
        backgroundColor: '#FFC646',
        borderRadius: 8,
        alignItems: 'center',
        alignSelf: 'center',
        justifyContent: 'center',
        alignContent: 'center',
        paddingHorizontal: 24,
        height: 44
    }
}