import { Service, Application, HttpManager, Managers } from '@levelapp/softfabric';

export default class ProjectsService extends Service
{
    httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);    
    auth: any = Application.current.resolve(Managers.AUTH_MANAGER);   

    async getByCode(code: string,callback: Function)
    {
        this.httpClient.get("api/projects/ByCode/"+code, []).then((response: any) => 
        {
            if (response != undefined)
            {
                callback(response.data.data);
            }
        });
    }    
}