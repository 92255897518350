import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import CalendarViewState from './CalendarViewState';
import CalendarViewProps from './CalendarViewProps';

export default class CalendarViewModel extends ContentViewModel<CalendarViewState, CalendarViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings

    }
}