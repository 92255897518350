import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import HexagonViewState from './HexagonViewState';
import HexagonViewProps from './HexagonViewProps';

export default class HexagonViewModel extends ContentViewModel<HexagonViewState, HexagonViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings

    }

    formatRate() {
        return this.props().project.annualInterestRate + " %";
    }

    formatDuration() {
        return this.props().project.duration + " ans";
    }
}