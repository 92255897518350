import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import DocumentsViewState from './DocumentsViewState';
import DocumentsViewProps from './DocumentsViewProps';

export default class DocumentsViewModel extends ContentViewModel<DocumentsViewState, DocumentsViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings

    }
}