import React from 'react';
import { ContentView } from '@levelapp/softfabric';
import InvestBoxViewState from './InvestBoxViewState';
import InvestBoxViewProps from './InvestBoxViewProps';
import InvestBoxViewStyle from './InvestBoxViewStyle';
import InvestBoxViewModel from './InvestBoxViewModel';
import { Image, Text, View } from 'react-native';
import Helpers from '../../../../../Common/Helpers/AmountHelpers';

export default class InvestBoxView extends ContentView<InvestBoxViewProps, InvestBoxViewState, InvestBoxViewModel, InvestBoxViewStyle> {
  /* CONSTRUCTOR */
  constructor(props: InvestBoxViewProps) {
    super(props);

    // Binding
    this.bind(new InvestBoxViewModel(this), new InvestBoxViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={this.style.box}>
        <Image source={require('./assets/background.png')} height={690} width={448} style={this.style.imageBackground} />
        <Text style={this.style.title}>{Helpers.formatAmount(this.props.project.currentInvest)}</Text>
        <View style={this.style.amountsBeforBarView}>
          <View style={{ flex: 1 }}>
            <Text style={this.style.amountsText}>Min : {Helpers.formatAmount(this.props.project.minimumInvest)}</Text>
          </View>
          <View style={this.style.amountsViewsRight}>
            <Text style={this.style.amountsTextRight}>Max : {Helpers.formatAmount(parseFloat(this.props.project.maximumInvest))}</Text>
          </View>
        </View>
        <Image source={require('./tmpBar.png')} height={27} width={408} style={{ height: 27, width: 408, maxWidth: '100%' }}  resizeMode="contain" />
        <View style={this.style.amountsView}>
          <View style={{ flex: 1 }}>
            <Text style={this.style.amountsText}>{this.binding.translate("financed")} : {Helpers.formatAmount(this.props.project.currentInvest)}</Text>
          </View>
          <View style={this.style.amountsViewsRight}>
            <Text style={this.style.amountsTextRight}>{this.binding.translate("toFinance")} : {this.binding.getToFinancedAmount()}</Text>
          </View>
        </View>
        <View>
          <Text style={this.style.titleText}>{this.props.project.projectName.toUpperCase()}</Text>
        </View>
        <View style={this.style.infosView}>
          <View>
            <Text style={this.style.infoText}>{this.binding.translate("place")}</Text>
            <Text style={this.style.infoText}>{this.binding.translate("issueDate")}</Text>
            <Text style={this.style.infoText}>{this.binding.translate("endDate")}</Text>
            <Text style={this.style.infoText}>{this.binding.translate("maturity")}</Text>
            <Text style={this.style.infoText}>{this.binding.translate("rate")}</Text>
            <Text style={this.style.infoText}>{this.binding.translate("minimiumInvest")}</Text>
          </View>
          <View style={this.style.infosViewValues}>
            <Text style={this.style.infoValue}>
              {this.props.project.address ? this.props.project.address.locality : '-'}
            </Text>
            <Text style={this.style.infoValue}>Bruxelles</Text>
            <Text style={this.style.infoValue}>{this.props.project.issueDate}</Text>
            <Text style={this.style.infoValue}>-</Text>
            <Text style={this.style.infoValue}>{this.binding.formatMaturity()}</Text>
            <Text style={this.style.infoValue}>{this.binding.formatRate()}</Text>
            <Text style={this.style.infoValue}>{Helpers.formatAmount(this.props.project.minimumTicket)}</Text>
          </View>
        </View>
        <div style={{ cursor: 'pointer', width: '100%' }}>
          <View style={this.style.btn}>
            <Text style={this.style.btnText}>{this.binding.translate("invest")}</Text>
          </View>
        </div>
      </View>
    );
  }
}