import React from 'react';
import { ContentView } from '@levelapp/softfabric';
import LeaderViewState from './LeaderViewState';
import LeaderViewProps from './LeaderViewProps';
import LeaderViewStyle from './LeaderViewStyle';
import LeaderViewModel from './LeaderViewModel';
import { TranslationView } from '@levelapp/softfabric-ui';
import { Image, Text, View } from 'react-native';
import Helpers from '../../../../../Common/Helpers/AmountHelpers';

export default class LeaderView extends ContentView<LeaderViewProps, LeaderViewState, LeaderViewModel, LeaderViewStyle> {
  /* CONSTRUCTOR */
  constructor(props: LeaderViewProps) {
    super(props);

    // Binding
    this.bind(new LeaderViewModel(this), new LeaderViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={this.style.page}>
        <TranslationView style={this.style.title}>leader</TranslationView>
        <TranslationView style={this.style.subTitle}>leader.organigram</TranslationView>
        <Image style={this.style.organigram} source={{ uri: Helpers.getPropertyValue("ORGANIGRAM", this.props.project.projectPropertyValues) }} />
        <Text style={this.style.text}>{Helpers.getPropertyValue("LEADER_DESCRIPTION", this.props.project.projectPropertyValues)}</Text>
        <TranslationView style={this.style.subTitle}>leader</TranslationView>
        <Text style={this.style.text}>{Helpers.getPropertyValue("LEADER_BIO", this.props.project.projectPropertyValues)}</Text>
        <Image style={this.style.organigram} source={{ uri: Helpers.getPropertyValue("LEADER_PICTURE", this.props.project.projectPropertyValues) }} />

        <View>
          <View>
            <View style={this.style.tableHeader}>
              <Text style={this.style.tableTitle}>{Helpers.getPropertyValue("LEADER_NAME", this.props.project.projectPropertyValues)}</Text>
            </View>
            {this.props.project.projectPropertyValues.filter(x => x.projectPropertyKey.projectPropertyType.key == "PROJECT_LEADER_ARRAY").map((property: any, index: number) => {
              return (
                <View key={index} style={this.style.row}>
                  <View style={this.style.borderView}>
                    <Text style={this.style.arrayKey}>{Helpers.getCorrectTranslation(property.projectPropertyKey.title)}</Text>
                  </View>
                  <View style={this.style.borderView}>
                    <Text style={this.style.arrayValue}>{Helpers.getPropertyValue(property.projectPropertyKey.key, this.props.project.projectPropertyValues)}</Text>
                  </View>
                </View>
              )
            })}
          </View>
        </View>
      </View>
    );
  }
}