import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import PreviousProjectsViewState from './PreviousProjectsViewState';
import PreviousProjectsViewProps from './PreviousProjectsViewProps';

export default class PreviousProjectsViewModel extends ContentViewModel<PreviousProjectsViewState, PreviousProjectsViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings

    }
}