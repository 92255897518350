import React from 'react';
import { ContentView } from '@levelapp/softfabric';
import CalendarViewState from './CalendarViewState';
import CalendarViewProps from './CalendarViewProps';
import CalendarViewStyle from './CalendarViewStyle';
import CalendarViewModel from './CalendarViewModel';
import { Text, View } from 'react-native';
import { TranslationView } from '@levelapp/softfabric-ui';
import ProjectCalendar from '../../../../../Common/DTO/ProjectCalendar';
import Helpers from '../../../../../Common/Helpers/AmountHelpers';
import HexagonView from './components/hexagon/HexagonView';

export default class CalendarView extends ContentView<CalendarViewProps, CalendarViewState, CalendarViewModel, CalendarViewStyle> {
  /* CONSTRUCTOR */
  constructor(props: CalendarViewProps) {
    super(props);

    // Binding
    this.bind(new CalendarViewModel(this), new CalendarViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={this.style.page}>
        <TranslationView style={this.style.title}>calendar</TranslationView>

        <View style={this.style.container}>
          {/* Boule en haut */}
          <View style={this.style.circle} />

          {/* Ligne verticale */}
          <View style={this.style.arrowView} />

          {/* Flèche en bas */}
          <View style={this.style.triangle} />
        </View>

        {this.props.calendars.map((calendar: ProjectCalendar, index: number) => {
          return (
            <View key={index} style={this.style.row}>
              <HexagonView title={calendar.order.toString()} />
              <View style={this.style.textView}>
                <Text style={this.style.date}>{Helpers.getCorrectTranslation(calendar.calendarDate)}</Text>
                <Text style={this.style.event}>{Helpers.getCorrectTranslation(calendar.calendarEvent)}</Text>
              </View>
            </View>
          )
        })}
      </View>
    );
  }
}