import { ContentViewStyle } from '@levelapp/softfabric';
import { StyleProp, TextStyle, ViewStyle } from 'react-native';

export default class HeaderViewStyle extends ContentViewStyle {
    header: StyleProp<ViewStyle> = {
        height: 95,
        flexDirection: 'row',
        alignItems: 'center'
    }

    circle: StyleProp<ViewStyle> = {
        height: 63,
        width: 63,
        backgroundColor: '#FFC646',
        borderRadius: 63,
    }

    title: StyleProp<TextStyle> = {
        fontSize: 24,
        color: '#535353',
        fontFamily: 'Montserrat',
        marginLeft: 8
    }

    menuItem: StyleProp<TextStyle> = {
        fontSize: 18,
        color: '#535353',
        fontFamily: 'Montserrat',
        marginLeft: 36,
    }

    menu: StyleProp<ViewStyle> = {
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1,
        justifyContent: 'flex-end'
    }


}