import {ContentViewStyle} from '@levelapp/softfabric';
import {StyleProp,TextStyle,ViewStyle} from 'react-native';

export default class VideosViewStyle extends ContentViewStyle
{
    page: StyleProp<ViewStyle> = {
        display: 'flex'
    }

    iframe: StyleProp<ViewStyle> = {
        marginTop: 32
    }

    title: StyleProp<TextStyle> = {
        fontSize: 24,
        color: '#535353',
        fontFamily: 'Montserrat',
        fontWeight: '700', 
        marginTop: 88
    }
}