import React from 'react';
import {ContentView} from '@levelapp/softfabric';
import HexagonViewState from './HexagonViewState';
import HexagonViewProps from './HexagonViewProps';
import HexagonViewStyle from './HexagonViewStyle';
import HexagonViewModel from './HexagonViewModel';
import { Text, View } from 'react-native';

export default class HexagonView extends ContentView<HexagonViewProps, HexagonViewState, HexagonViewModel, HexagonViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:HexagonViewProps)
  {
    super(props);

    // Binding
    this.bind(new HexagonViewModel(this), new HexagonViewStyle(this));
  }

   /* RENDERING */
   render() {
    return (
      <View style={this.style.hexagon}>
        <View style={this.style.hexagonInner}>
          <Text style={this.style.title}>{this.props.title}</Text>         
        </View>
        <View style={this.style.hexagonBefore} />
        <View style={this.style.hexagonAfter} />
      </View>
    );
  }
}