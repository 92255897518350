import React from 'react';
import { ContentView } from '@levelapp/softfabric';
import QuestionsViewState from './QuestionsViewState';
import QuestionsViewProps from './QuestionsViewProps';
import QuestionsViewStyle from './QuestionsViewStyle';
import QuestionsViewModel from './QuestionsViewModel';
import { TranslationView } from '@levelapp/softfabric-ui';
import { Text, View } from 'react-native';
import ProjectQuestion from '../../../../../Common/DTO/ProjectQuestion';
import Helpers from '../../../../../Common/Helpers/AmountHelpers';

export default class QuestionsView extends ContentView<QuestionsViewProps, QuestionsViewState, QuestionsViewModel, QuestionsViewStyle> {
  /* CONSTRUCTOR */
  constructor(props: QuestionsViewProps) {
    super(props);

    // Binding
    this.bind(new QuestionsViewModel(this), new QuestionsViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={this.style.page}>
        <TranslationView style={this.style.title}>attentionPoints</TranslationView>
        <View style={this.style.row}>
          {this.props.projectQuestions.map((question: ProjectQuestion, index: number) => {
            return (
              <View key={index} style={[
                this.style.box,
                index !== this.props.projectQuestions.length - 1 && { marginRight: 10 } 
              ]}>
                <Text style={this.style.boxTitle}>{Helpers.getCorrectTranslation(question.title)}</Text>
                <Text style={this.style.boxText}>{Helpers.getCorrectTranslation(question.response)}</Text>
              </View>
            )
          })}
        </View>
      </View>
    );
  }
}