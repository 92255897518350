import React from 'react';
import { ContentView } from '@levelapp/softfabric';
import WarningViewState from './WarningViewState';
import WarningViewProps from './WarningViewProps';
import WarningViewStyle from './WarningViewStyle';
import WarningViewModel from './WarningViewModel';
import { TranslationView } from '@levelapp/softfabric-ui';
import { Image, Text, View } from 'react-native';
import Helpers from '../../../../../Common/Helpers/AmountHelpers';

export default class WarningView extends ContentView<WarningViewProps, WarningViewState, WarningViewModel, WarningViewStyle> {
  /* CONSTRUCTOR */
  constructor(props: WarningViewProps) {
    super(props);

    // Binding
    this.bind(new WarningViewModel(this), new WarningViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={this.style.page}>
        <TranslationView style={this.style.title}>warning</TranslationView>

        {Helpers.getCorrectTranslation(this.props.text)
          .split(/\r?\n\s*\n/) // Divise le texte en paragraphes sur des sauts de ligne doubles
          .filter(paragraph => paragraph.trim() !== '') // Filtre les paragraphes vides
          .map((paragraph, index) => (
            <View key={index} style={this.style.box}>
              <Image source={require('./assets/info.png')} style={{ height: 56, width: 56 }} />
              <Text style={this.style.text}>{paragraph}</Text>
            </View>
          ))
        }

      </View>

    );
  }
}