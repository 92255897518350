import {ContentViewStyle} from '@levelapp/softfabric';
import {StyleProp,TextStyle,ViewStyle} from 'react-native';

export default class DocumentsViewStyle extends ContentViewStyle
{
    page: StyleProp<ViewStyle> = {
    }

    title: StyleProp<TextStyle> = {
        fontSize: 36,
        color: '#535353',
        fontFamily: 'Montserrat',
        fontWeight: '700', 
        marginTop: 113,
        marginBottom: 80
    }

    documents: StyleProp<ViewStyle> = {
       flexDirection: 'row', 
       flexWrap: 'wrap'
    }

    row: StyleProp<ViewStyle> = {
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: 16,
        marginBottom: 20
    }

    documentTitle: StyleProp<TextStyle> = {
        fontSize: 20,
        color: '#535353',
        fontFamily: 'Montserrat',
        fontWeight: '400',
        marginLeft: 8
    }

    infoText: StyleProp<TextStyle> = {
        fontSize: 12,
        color: '#535353',
        fontFamily: 'Montserrat',
        fontWeight: '400',
        marginTop: 5
    }
}