import { ContentViewStyle } from '@levelapp/softfabric';
import { StyleProp, TextStyle, ViewStyle } from 'react-native';

export default class HexagonViewStyle extends ContentViewStyle {
    hexagon: StyleProp<ViewStyle> = {
        width: 46,  // La largeur correspond à la moitié de la hauteur totale pour obtenir un hexagone régulier
        height: 53, // Hauteur totale de l'hexagone
      }
      
      hexagonInner: StyleProp<ViewStyle> = {
        alignItems: 'center',
        justifyContent: 'center',
        width: 46,      // Largeur ajustée
        height: 26,     // Hauteur centrale (la moitié de la hauteur totale sans les pointes)
        backgroundColor: '#FFC646',
      }
      
      hexagonBefore: StyleProp<ViewStyle> = {
        position: 'absolute',
        top: -12,           // Ajustement de la position de la pointe supérieure
        left: 0,
        width: 0,
        borderLeftWidth: 23,    // La moitié de la largeur de l'hexagone
        borderLeftColor: 'transparent',
        borderRightWidth: 23,   // La moitié de la largeur de l'hexagone
        borderRightColor: 'transparent',
        borderBottomWidth: 13,  // La hauteur de la pointe supérieure
        borderBottomColor: '#FFC646',
      }
      
      hexagonAfter: StyleProp<ViewStyle> = {
        position: 'absolute',
        bottom: 15,        // Ajustement de la position de la pointe inférieure
        left: 0,
        width: 0,
        borderLeftWidth: 23,    // La moitié de la largeur de l'hexagone
        borderLeftColor: 'transparent',
        borderRightWidth: 23,   // La moitié de la largeur de l'hexagone
        borderRightColor: 'transparent',
        borderTopWidth: 13,     // La hauteur de la pointe inférieure
        borderTopColor: '#FFC646',
      }
      


    title: StyleProp<TextStyle> = {
        fontSize: 24,
        color: '#535353',
        fontFamily: 'Montserrat',
        fontWeight: '500',
    }

    value: StyleProp<TextStyle> = {
        fontSize: 24,
        color: '#535353',
        fontFamily: 'Montserrat',
        fontWeight: '700',
    }
}