import React from 'react';
import { ContentView } from '@levelapp/softfabric';
import ProjectPresentationViewState from './ProjectPresentationViewState';
import ProjectPresentationViewProps from './ProjectPresentationViewProps';
import ProjectPresentationViewStyle from './ProjectPresentationViewStyle';
import ProjectPresentationViewModel from './ProjectPresentationViewModel';
import { Image, Text, View } from 'react-native';
import Helpers from '../../../../../Common/Helpers/AmountHelpers';

export default class ProjectPresentationView extends ContentView<ProjectPresentationViewProps, ProjectPresentationViewState, ProjectPresentationViewModel, ProjectPresentationViewStyle> {
  /* CONSTRUCTOR */
  constructor(props: ProjectPresentationViewProps) {
    super(props);

    // Binding
    this.bind(new ProjectPresentationViewModel(this), new ProjectPresentationViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View>
        <Text style={this.style.boxDescription}>{Helpers.getCorrectTranslation(this.props.project.projectDescription)}</Text>
        <View style={this.style.line}>
          {this.props.project.projectPropertyValues.filter(x => x.projectPropertyKey.projectPropertyType.key == "GLOBAL").map((property: any, index: number) => {
            return (
              <View key={index} style={[this.style.box, index != 0 ? { marginLeft: 8 } : {}]}>
                <Image style={this.style.icon} source={{ uri: this.binding.getPropertyUrl(property.projectPropertyKey.key) }} />
                <View>
                  <Text style={this.style.boxTitle}>{Helpers.getCorrectTranslation(property.projectPropertyKey.title)}</Text>
                  <Text style={this.style.boxSubTitle}>{this.binding.getpropertyValue(property)}</Text>
                </View>
              </View>
            )
          })}
        </View>
      </View>
    );
  }
}