import React from 'react';
import { ContentView } from '@levelapp/softfabric';
import HeaderViewState from './HeaderViewState';
import HeaderViewProps from './HeaderViewProps';
import HeaderViewStyle from './HeaderViewStyle';
import HeaderViewModel from './HeaderViewModel';
import { Text, View } from 'react-native';
import { TranslationView } from '@levelapp/softfabric-ui';

export default class HeaderView extends ContentView<HeaderViewProps, HeaderViewState, HeaderViewModel, HeaderViewStyle> {
  /* CONSTRUCTOR */
  constructor(props: HeaderViewProps) {
    super(props);

    // Binding
    this.bind(new HeaderViewModel(this), new HeaderViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={this.style.header}>
        <View style={this.style.circle} />
        <Text style={this.style.title}>BeeBonds</Text>
        <View style={this.style.menu}>
          <div style={{ cursor: "pointer" }}>
            <a href='https://www.beebonds.com' style={{textDecoration: 'none'}} target='_blank'>
              <TranslationView style={this.style.menuItem}>home</TranslationView>
            </a>
          </div>
          <div style={{ cursor: "pointer" }}>
            <a href='https://www.beebonds.com/projets' style={{textDecoration: 'none'}} target='_blank'>
              <TranslationView style={this.style.menuItem}>projects</TranslationView>
            </a>
          </div>
          <div style={{ cursor: "pointer" }}>
            <Text style={this.style.menuItem}>FR</Text>
          </div>
        </View >
      </View >
    );
  }
}