import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import WarningViewState from './WarningViewState';
import WarningViewProps from './WarningViewProps';

export default class WarningViewModel extends ContentViewModel<WarningViewState, WarningViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings

    }
}