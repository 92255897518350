import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import FinanceSummaryViewState from './FinanceSummaryViewState';
import FinanceSummaryViewProps from './FinanceSummaryViewProps';

export default class FinanceSummaryViewModel extends ContentViewModel<FinanceSummaryViewState, FinanceSummaryViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings

    }
}