import React from 'react';
import { ContentView } from '@levelapp/softfabric';
import LocalisationViewState from './LocalisationViewState';
import LocalisationViewProps from './LocalisationViewProps';
import LocalisationViewStyle from './LocalisationViewStyle';
import LocalisationViewModel from './LocalisationViewModel';
import { TranslationView } from '@levelapp/softfabric-ui';
import { Image, Text, View } from 'react-native';
import Helpers from '../../../../../Common/Helpers/AmountHelpers';

export default class LocalisationView extends ContentView<LocalisationViewProps, LocalisationViewState, LocalisationViewModel, LocalisationViewStyle> {
  /* CONSTRUCTOR */
  constructor(props: LocalisationViewProps) {
    super(props);

    // Binding
    this.bind(new LocalisationViewModel(this), new LocalisationViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View>
        <TranslationView style={this.style.title}>localisationTitle</TranslationView>
        <Text style={this.style.text}>{Helpers.getCorrectTranslation(this.props.project.projectLocalisationText)}</Text>
        <View style={this.style.line}>
          {this.props.images.filter((x:any) => x.type == 'LOCALISATION').map((image: any, index: number) => {
            return (
              <Image key={index} style={this.style.image} source={{ uri: image.url }} />
            )
          })}
        </View>
        <View style={this.style.map}>
          <iframe src={this.binding.constructGoogleAddress()}  height={"100%"}></iframe>
        </View>
      </View>
    );
  }
}