import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import InvestBoxViewState from './InvestBoxViewState';
import InvestBoxViewProps from './InvestBoxViewProps';
import Helpers from '../../../../../Common/Helpers/AmountHelpers';

export default class InvestBoxViewModel extends ContentViewModel<InvestBoxViewState, InvestBoxViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings

    }

    formatMaturity() {
        return (this.props().project.duration > 1) ? this.props().project.duration + " " +  this.translate("years")
        : this.props().project.duration + " " + this.translate("year");
    }

    formatRate() {
        return this.props().project.annualInterestRate + "%";
    }

    getToFinancedAmount() {
        const remaining = parseFloat(this.props().project.maximumInvest) - this.props().project.currentInvest;

        return Helpers.formatAmount(remaining);
    }
}