import React from 'react';
import { ContentView } from '@levelapp/softfabric';
import DetailsViewState from './DetailsViewState';
import DetailsViewProps from './DetailsViewProps';
import DetailsViewStyle from './DetailsViewStyle';
import DetailsViewModel from './DetailsViewModel';
import { Image, View } from 'react-native';
import HeaderView from './components/header/HeaderView';
import PresentationView from './components/presentation/PresentationView';
import ProjectPresentationView from './components/projectpresentation/ProjectPresentationView';
import InvestBoxView from './components/investbox/InvestBoxView';
import AdvantagesView from './components/advantages/AdvantagesView';
import QuestionsView from './components/questions/QuestionsView';
import FooterView from './components/footer/FooterView';
import DocumentsView from './components/documents/DocumentsView';
import NavigationBarView from './components/navigationbar/NavigationBarView';
import LocalisationView from './components/localisation/LocalisationView';
import ImagesPresentationView from './components/imagespresentation/ImagesPresentationView';
import InvestCtaView from './components/investcta/InvestCtaView';
import LeaderView from './components/leader/LeaderView';
import PreviousProjectsView from './components/previousprojects/PreviousProjectsView';
import CalendarView from './components/calendar/CalendarView';
import FinanceSummaryView from './components/financesummary/FinanceSummaryView';
import WarningView from './components/warning/WarningView';
import VideosView from './components/videos/VideosView';

export default class DetailsView extends ContentView<DetailsViewProps, DetailsViewState, DetailsViewModel, DetailsViewStyle> {
  /* CONSTRUCTOR */
  constructor(props: DetailsViewProps) {
    super(props);

    // Binding
    this.bind(new DetailsViewModel(this), new DetailsViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={this.style.page}>
        <HeaderView />
        {this.state.project &&
          <View>
            <PresentationView project={this.state.project} />
            <View style={this.style.columns}>
              <View style={[this.style.column]}>
                <View style={{ height: 54 }} />
                <NavigationBarView />
                <ProjectPresentationView project={this.state.project} />
                <ImagesPresentationView images={this.state.project.projectImages} />
              </View>
              <View style={this.style.columnRight}>
                <InvestBoxView project={this.state.project} />
              </View>
            </View>
            <View style={{ height: 129 }} />
            <View style={this.style.columns}>
              <View style={this.style.column}>
                <AdvantagesView projectBenefits={this.state.project.projectBenefits} />               
              </View>
              <View style={this.style.columnRight}>
                <Image source={require('./../../../Common/assets/advantagesImage.png')} style={{ height: 583, width: 744 }} />
              </View>

            </View>
            <View style={this.style.columns}>
              <View style={this.style.column}>
                <VideosView properties={this.state.project.projectPropertyValues} />
                <View style={{ height: 88 }} />
                <LocalisationView project={this.state.project} images={this.state.project.projectImages}></LocalisationView>
                <CalendarView calendars={this.state.project.projectCalendars} />
                <LeaderView project={this.state.project}></LeaderView>
                <PreviousProjectsView previousProjects={this.state.project.previousProjects} />
                <FinanceSummaryView project={this.state.project} />
                <View style={{ height: 88 }} />
                <QuestionsView projectQuestions={this.state.project.projectQuestions} />
                <DocumentsView projectDocuments={this.state.project.projectDocuments} documentsLegalInfoText={this.state.project.documentsLegalInfoText} />
              </View>
              <View style={this.style.columnRight}>
              </View>
            </View>

          </View>
        }
        {this.state.project &&
          <View>
            <InvestCtaView project={this.state.project} />
            <WarningView text={this.state.project.aboutLegalInfoText} />
          </View>
        }

        <FooterView />
      </View>
    );
  }
}