import {ContentViewStyle} from '@levelapp/softfabric';
import {StyleProp,TextStyle,ViewStyle} from 'react-native';

export default class QuestionsViewStyle extends ContentViewStyle
{
    page: StyleProp<ViewStyle> = {
        flex: 1,
    }

    title: StyleProp<TextStyle> = {
        fontSize: 36,
        color: '#535353',
        fontFamily: 'Montserrat',
        fontWeight: '700', 
        marginBottom: 64
    }

    box: StyleProp<ViewStyle> = {
        padding: 32,
        borderRadius: 24,
        shadowColor: "#0000006b",
        shadowOffset: {
            width: 1,
            height: 0,
        },
        shadowOpacity: 0.35,  
        shadowRadius: 20.32,
        marginBottom: 20
    }

    boxTitle: StyleProp<TextStyle> = {
        fontSize: 16,
        color: '#535353',
        fontFamily: 'Montserrat',
        fontWeight: '700', 
    }

    boxText: StyleProp<TextStyle> = {
        fontSize: 16,
        color: '#535353',
        fontFamily: 'Montserrat',
        fontWeight: '400', 
        marginTop: 38, 
    }

    row: StyleProp<ViewStyle> = {
        flex: 1,
    }

}